import { calculatePeopleAndShowMessage } from './folks-selector.js'

const init = function () {

	$('.js-search-form-popup').off('click keypress').on('click keypress', '.js-quantity-button', function (e) {

		if (e.type === 'click' || ((e.type === 'keypress') && e.which === 13)) {

			var button = $(this),
				input = button.closest('.js-quantity-wrapper').find('input');

			var currentValue = parseInt(input.val()),
				newVal = '';

			if (button.attr('data-folks-selector-increase')) {

				newVal = currentValue + 1;
				var max = maxValue(input);
				if (newVal > max) newVal = max;
			}
			else {
				var smallestValue = parseInt(input.attr('min'));
				if (currentValue > smallestValue) {
					newVal = currentValue - 1;
				}
				else {
					newVal = smallestValue;
				}
				updateDependentInput(input, newVal);
			}

			input.val(newVal);
			calculatePeopleAndShowMessage(button.closest('.js-folks-selector-wrapper'));
		}
	});
}

const maxValue = function (input) {
	var max = parseInt(input.attr('max'));

	var combinedMax = combinedMaxValue(input);
	if (combinedMax === 0 || combinedMax > 0)
		max = Math.min(max, combinedMax);

	var dependentMax = dependentMaxValue(input);
	if (dependentMax === 0 || dependentMax > 0)
		max = Math.min(max, dependentMax);

	return max;
}

const combinedMaxValue = function (input) {

	var combinedMax = parseInt(input.attr('data-combined-max'));
	var combinedMaxInput = $('#' + input.attr('data-combined-max-with'));
	var otherInputvalue = parseInt(combinedMaxInput.val()) || 0;

	if (combinedMax > 0 && (otherInputvalue === 0 || otherInputvalue > 0))
		return combinedMax - otherInputvalue;
	else
		return null;
}

const dependentMaxValue = function (input) {

	var maxDependentOnInput = $('#' + input.attr('data-max-dependent-on'));
	var maxDependentValue = parseInt(maxDependentOnInput.val());

	if (maxDependentValue === 0 || maxDependentValue > 0)
		return maxDependentValue;
	else
		return null;
}

const updateDependentInput = function (input, newVal) {
	var dependentMaxInput = $('#' + input.attr('data-governs-max-of'));
	var dependentMaxInputValue = parseInt(dependentMaxInput.val());
	if (dependentMaxInputValue && dependentMaxInputValue > newVal)
		dependentMaxInput.val(newVal);
}

export default init;
export { maxValue, combinedMaxValue, dependentMaxValue, updateDependentInput, init };
